<template>
  <div id="faq-modal-component" class="card">
    <div class="faq-modal-header">
      <h2 class="faq-modal-header-text">
        {{ categoryName }}
      </h2>
    </div>
    <hr>
    <associated-pages
      :category-id="categoryId"
      :max-pages="3"
    />
    <div class="faq-modal-footer">
      <hr>
      <b-button
        class="category-card-button btn btn-primary rounded"
        @click="logFaqCategoryNavigation(categoryId, { action: 'visit', type: 'button', categoryName: categoryName })"
      >
        View Category
      </b-button>
    </div>
  </div>
</template>

<script>
import AssociatedPages from '@/components/Faq/AssociatedPages.vue'
import { faqMixin } from '@/mixins/faqMixin'

export default {
  name: 'FaqModal',
  components: {
    AssociatedPages,
  },
  mixins: [faqMixin],
  props: {
    categoryName:{
      type: String,
    },
    categoryId:{
      type: String,
    },
  },
}
</script>

<style lang="scss">
#faq-modal-component {
  vertical-align: top;
  width: 545px;
  min-height: 200px;
  margin-bottom:30px;
  hr {
    margin-bottom:0;
    margin-top: 0;
  }
  .faq-modal-header {
    display: flex;
    align-items: center;
    height: 70px;
  }
  .category-card-button {
    width: 150px;
    height: 35px;
    font-size: 15px;
    padding: 4px;
    background: #2EB2AE;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    margin-bottom: 10px;
  }
  .faq-modal-footer {
    margin-top: auto;
    height: 68px;
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:column;
    hr {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .faq-modal-header-text{
    margin: 0 0 0 29px;
    font-size: 24px;
  }
}
</style>
