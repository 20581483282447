var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "faq-landing-page" } },
    [
      _c(
        "div",
        { staticClass: "faq-landing-search-header" },
        [
          _c("h2", [_vm._v("What can we help you with?")]),
          _vm.loaded ? _c("faq-search-bar") : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "faq-landing-header" }, [
        _c("h1", [_vm._v("Frequently Asked Questions")]),
      ]),
      !_vm.loaded
        ? _c("ct-centered-spinner")
        : _c(
            "div",
            { staticClass: "faq-modals-holder" },
            _vm._l(_vm.visibleCategories, function (category) {
              return _c("faq-modal", {
                key: category.id,
                attrs: {
                  "category-name": category.name,
                  "category-id": category.id,
                },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }