var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", attrs: { id: "faq-modal-component" } },
    [
      _c("div", { staticClass: "faq-modal-header" }, [
        _c("h2", { staticClass: "faq-modal-header-text" }, [
          _vm._v("\n      " + _vm._s(_vm.categoryName) + "\n    "),
        ]),
      ]),
      _c("hr"),
      _c("associated-pages", {
        attrs: { "category-id": _vm.categoryId, "max-pages": 3 },
      }),
      _c(
        "div",
        { staticClass: "faq-modal-footer" },
        [
          _c("hr"),
          _c(
            "b-button",
            {
              staticClass: "category-card-button btn btn-primary rounded",
              on: {
                click: function ($event) {
                  return _vm.logFaqCategoryNavigation(_vm.categoryId, {
                    action: "visit",
                    type: "button",
                    categoryName: _vm.categoryName,
                  })
                },
              },
            },
            [_vm._v("\n      View Category\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }