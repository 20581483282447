<template>
  <b-container id="faq-landing-page">
    <div class="faq-landing-search-header">
      <h2>What can we help you with?</h2>
      <faq-search-bar v-if="loaded" />
    </div>
    <div class="faq-landing-header">
      <h1>Frequently Asked Questions</h1>
    </div>
    <ct-centered-spinner v-if="!loaded" />
    <div v-else class="faq-modals-holder">
      <faq-modal
        v-for="category in visibleCategories"
        :key="category.id"
        :category-name="category.name"
        :category-id="category.id"
      />
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FaqModal from '@/components/Faq/FaqModal.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import FaqSearchBar from '@/components/Faq/FaqSearchBar.vue'

export default {
  name: 'Faq',
  components: { CtCenteredSpinner, FaqModal, FaqSearchBar },
  computed: {
    ...mapGetters('faq', ['faqInformation']),
    visibleCategories() {
      return this.faqInformation.filter(category => category.is_visible)
    },
    loaded() {
      return this.faqInformation && this.faqInformation.length > 0
    },
  },
  async mounted() {
    if (!this.loaded) {
      await this.fetchFaqInformation()
    }
  },
  methods: {
    ...mapActions('faq', ['fetchFaqInformation']),
  },
}
</script>

<style lang="scss">
#faq-landing-page {
  h2 {
    font-weight: 700;
  }
  .faq-landing-header {
    h1 {
      text-align:center;
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
  .faq-landing-search-header {
    margin-top:86px;
    margin-bottom:75px;
    h2 {
      font-size: 34px;
    }
  }
  .faq-landing-search-header > h2 {
    margin-bottom: 24px;
    text-align: center;
  }
  .faq-modals-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

</style>
